import React from "react";

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
  id?: string;
}

export const CorporateClientIcon = (props: iconProps) => {
  return (
    <svg
      width={props.width ? props.width : "27"}
      height={props.height ? props.height : "29"}
      id={props.id}
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M27.1874 2.89484H10.0986V28.9655H27.1874V2.89484Z" fill={props.color || "#235DC1"} />
        <path d="M17.0888 0H0V29H17.0888V0Z" fill={props.color || "#5D8DE4"} />
        <path d="M7.44053 3.47369H3.38916V7.93257H7.44053V3.47369Z" fill="#ADC5F2" />
        <path d="M13.4962 3.47369H9.44482V7.93257H13.4962V3.47369Z" fill="#ADC5F2" />
        <path d="M7.44053 10.5163H3.38916V14.9752H7.44053V10.5163Z" fill="#ADC5F2" />
        <path d="M13.4962 10.5163H9.44482V14.9752H13.4962V10.5163Z" fill="#ADC5F2" />
        <path d="M7.44053 17.5245H3.38916V21.9834H7.44053V17.5245Z" fill="#ADC5F2" />
        <path d="M13.4962 17.5245H9.44482V21.9834H13.4962V17.5245Z" fill="#ADC5F2" />
        <path d="M18.94 7.16364H14.8887V11.6225H18.94V7.16364Z" fill="#5D8DE4" />
        <path d="M24.9964 7.16364H20.9451V11.6225H24.9964V7.16364Z" fill="#5D8DE4" />
        <path d="M18.94 14.2061H14.8887V18.665H18.94V14.2061Z" fill="#5D8DE4" />
        <path d="M24.9964 14.2061H20.9451V18.665H24.9964V14.2061Z" fill="#5D8DE4" />
        <path d="M18.94 21.2056H14.8887V25.6645H18.94V21.2056Z" fill="#5D8DE4" />
        <path d="M24.9964 21.2056H20.9451V25.6645H24.9964V21.2056Z" fill="#5D8DE4" />
      </g>
      <defs>
        <clipPath id="corporateClient0">
          <rect width={props.width ? props.width : "27"} height={props.width ? props.height : "29"} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
