/* global OfficeRuntime, process */
/* eslint-disable react/no-unescaped-entities */

import * as React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Combobox,
  ComboboxProps,
  makeStyles,
  Option,
  OptionOnSelectData,
  Persona,
  SelectionEvents,
  shorthands,
  Skeleton,
  SkeletonItem,
  Spinner,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import { getClientHeaderData, searchClients } from "../../api/MiddleTier";
import { handleClientSideErrors } from "../../helpers/error-handler";
import { dialogFallback } from "../../helpers/fallbackauthdialog";
import { Client } from "../models/Client";
import { useTranslation } from "react-i18next";
import {
  Virtualizer,
  VirtualizerContextProvider,
  useStaticVirtualizerMeasure,
} from "@fluentui/react-components/unstable";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SearchResults } from "../../api/model/SearchResults";
import { Partner } from "../../api/model/Partner";
import { AxiosResponse } from "axios";
import { SearchResult } from "../../api/model/SearchResult";
import { CorporateClientIcon } from "../icons/CorporateClientIcon";
import { PrivateClientIcon } from "../icons/PrivateClientIcon";
import { ClientHeader } from "../../api/model/ClientHeader";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
});

const useComboBoxStyles = makeStyles({
  listbox: {
    maxHeight: "250px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  option: {
    height: "32px",
    // Ensure the option text doesn't wrap
    whiteSpace: "nowrap",
    // Ensure the option text is in single line
    textOverflow: "ellipsis",
  },
});

export interface IClientSearchProps {
  options: Client[];
  onOptionSelect: (__event: SelectionEvents, data: OptionOnSelectData) => void;
  disabled: boolean;
  selectedKey?: string;
}

export const ClientSearch = ({ options, onOptionSelect, disabled, selectedKey }: IClientSearchProps) => {
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();
  const [matchingOptions, setMatchingOptions] = useState([...options]);
  const [matchingOptionsTotal, setMatchingOptionsTotal] = useState<number>(options.length);
  const skipItems = useRef<number>(0);
  const [customSearch, setCustomSearch] = useState<string | undefined>();
  const styles = useStyles();
  const comboBoxStyles = useComboBoxStyles();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [fetchIndex, setFetchIndex] = useState<number>(0);
  const [isClientListOpen, setIsClientListOpen] = useState<boolean>(false);

  const onChange: ComboboxProps["onChange"] = (event) => {
    const value = event.target.value.trim();
    const matches = options.filter((option) => option.clientName.toLowerCase().indexOf(value.toLowerCase()) === 0);
    setMatchingOptions(matches);
    setMatchingOptionsTotal(matches.length);
    if (value.length && matches.length < 1) {
      setCustomSearch(value);
      setMatchingOptionsTotal(0);
    }
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getAddressFromSearchItem = (document: Partner) => {
    const address = document.partnerAddress;
    return address
      ? `${address.street || ""}${address.house ? `${address.street ? " " : ""}${address.house}` : ""}${
          address.buildingFloorDoor ? `${address.street || address.house ? " " : ""}${address.buildingFloorDoor}` : ""
        }${address.zipCode || address.city ? "," : ""}${address.zipCode ? ` ${address.zipCode}` : ""}${
          address.zipCode || ""
        }${address.city ? ` ${address.city}` : ""}`
      : "";
  };

  const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    const matchingOption =
      data.optionText && matchingOptions.map((option) => option.clientName).includes(data.optionText);
    if (matchingOption) {
      onOptionSelect(event, data);
    } else if (data.optionValue && !isJsonString(data.optionValue)) {
      onSearch();
    }
  };

  const onSearch = async (flagIndex?: number) => {
    let middletierToken: string = await OfficeRuntime.auth.getAccessToken({
      forMSGraphAccess: false,
    });
    try {
      if (!flagIndex) {
        skipItems.current = 0;
        setFetchIndex(0);
      }

      const response = await searchClients(middletierToken, customSearch, flagIndex);
      const res: SearchResults = response.data;
      const fetchedOptions = res.results.map<Client>((client: SearchResult) => {
        const { document } = client;
        return {
          clientId: `${document.partnerID}`,
          clientName: document.partnerName,
          internalNumber: document.partnerNumber,
          isOrganization: document.partnerType
            .map((partnerType) => `${partnerType}`)
            .includes(process.env.REACT_APP_BUSSINESS_OBJECT_TYPE_COMPANY_CLIENT),
          countryCodeId: document.countryCodeID,
          address: getAddressFromSearchItem(document),
          topLevelPartnerID: document.topLevelPartnerID,
          topLevelPartnerType: document.topLevelPartnerType,
          topLevelPartnerNameResolved:
            document.topLevelPartnerID === document.partnerID ||
            document.topLevelPartnerID === document.belongsToPartnerId,
          topLevelPartnerName:
            document.topLevelPartnerID === document.partnerID
              ? document.partnerName
              : document.topLevelPartnerID === document.belongsToPartnerId
              ? document.belongsToPartnerName
              : undefined,
        };
      });
      if (flagIndex) setMatchingOptions([...matchingOptions, ...fetchedOptions]);
      else setMatchingOptions(fetchedOptions);
      setMatchingOptionsTotal(res.count);
      skipItems.current = skipItems.current + res.results.length;
      if (!flagIndex) {
        setIsClientListOpen(true);
      }
    } catch (error: any) {
      // if handleClientSideErrors returns true then we will try to authenticate via the fallback
      // dialog rather than simply throw and error
      if (error.code) {
        if (handleClientSideErrors(error)) {
          dialogFallback((result: AxiosResponse<SearchResults>) => {
            let res: SearchResults = result.data;
            setMatchingOptions(
              res.results.map((client: SearchResult) => {
                const { document } = client;
                return {
                  clientId: `${document.partnerID}`,
                  clientName: document.partnerName,
                  countryCodeId: document.countryCodeID,
                  internalNumber: document.partnerNumber,
                  isOrganization: document.partnerType
                    .map((partnerType) => `${partnerType}`)
                    .includes(process.env.REACT_APP_BUSSINESS_OBJECT_TYPE_COMPANY_CLIENT),
                  address: getAddressFromSearchItem(document),
                  topLevelPartnerID: document.topLevelPartnerID,
                  topLevelPartnerType: document.topLevelPartnerType,
                  topLevelPartnerNameResolved:
                    document.topLevelPartnerID === document.partnerID ||
                    document.topLevelPartnerID === document.belongsToPartnerId,
                  topLevelPartnerName:
                    document.topLevelPartnerID === document.partnerID
                      ? document.partnerName
                      : document.topLevelPartnerID === document.belongsToPartnerId
                      ? document.belongsToPartnerName
                      : undefined,
                };
              })
            );
            setMatchingOptionsTotal(res.count);
            skipItems.current = skipItems.current + res.results.length;
            if (!flagIndex) {
              setIsClientListOpen(true);
            }
          });
        }
      } else {
        appInsights.trackException({ error });
      }
    }
  };

  const { virtualizerLength, bufferItems, bufferSize, scrollRef } = useStaticVirtualizerMeasure({
    defaultItemSize: 44,
    direction: "vertical",
  });

  useEffect(() => {
    if (
      currentIndex + virtualizerLength >= skipItems.current &&
      matchingOptionsTotal > matchingOptions.length &&
      skipItems.current > fetchIndex
    ) {
      setFetchIndex(skipItems.current);
      onSearch(skipItems.current);
    }
  }, [currentIndex, skipItems.current]);

  const getTopLevelPartnerName = async (client: Client) => {
    let middletierToken: string = await OfficeRuntime.auth.getAccessToken({
      forMSGraphAccess: false,
    });

    const response = await getClientHeaderData(middletierToken, client.topLevelPartnerID, client.topLevelPartnerType);
    const res = response.data;
    return res.find((header: ClientHeader) => header.businessObjectID === client.topLevelPartnerID)?.businessObjectName;
  };

  const updateClientTopLevelPartnerName = async (clientIndex: number, topLevelPartnerName: string) => {
    const updatedClient = { ...matchingOptions[clientIndex], topLevelPartnerName, topLevelPartnerNameResolved: true };
    const updatedMatchingOptions = [...matchingOptions];
    updatedMatchingOptions[clientIndex] = updatedClient;
    setMatchingOptions(updatedMatchingOptions);
  };

  return (
    <div className={styles.root}>
      <Combobox
        placeholder={t("oac.clientSearch.placeholder")}
        freeform
        autoComplete="off"
        size="large"
        onChange={onChange}
        onOptionSelect={onSelect}
        disabled={disabled}
        listbox={{ ref: scrollRef, className: comboBoxStyles.listbox }}
        open={isClientListOpen}
        onOpenChange={(__event, data) => {
          if (data.open !== isClientListOpen) {
            setIsClientListOpen(data.open);
          }
        }}
      >
        {customSearch ? (
          <Option key="freeform" text={customSearch} checkIcon={null}>
            {t("oac.searchCient.action", { customSearch })}
          </Option>
        ) : null}
        <VirtualizerContextProvider value={{ contextIndex: currentIndex, setContextIndex: setCurrentIndex }}>
          <Virtualizer
            numItems={matchingOptionsTotal}
            virtualizerLength={virtualizerLength}
            bufferItems={bufferItems}
            bufferSize={bufferSize}
            itemSize={44}
          >
            {(index) => {
              if (index >= matchingOptions.length && index < matchingOptionsTotal) {
                return (
                  <Skeleton key={`loading-${index}`}>
                    <SkeletonItem size={40} />
                  </Skeleton>
                );
              }

              const option = matchingOptions[index];
              return (
                <Tooltip
                  content={option.topLevelPartnerNameResolved ? option.topLevelPartnerName : <Spinner size="small" />}
                  key={option.clientId}
                  relationship="description"
                  visible={option.topLevelPartnerID ? undefined : false}
                  onVisibleChange={() => {
                    if (
                      option.topLevelPartnerID &&
                      option.topLevelPartnerType &&
                      !option.topLevelPartnerResolver &&
                      !option.topLevelPartnerNameResolved
                    ) {
                      option.topLevelPartnerResolver = getTopLevelPartnerName(option);
                      option.topLevelPartnerResolver.then((topLevelPartnerName) => {
                        updateClientTopLevelPartnerName(index, topLevelPartnerName);
                      });
                    }
                  }}
                >
                  <Option
                    key={option.clientId}
                    value={JSON.stringify(option)}
                    text={option.clientName}
                    className={comboBoxStyles.option}
                    aria-posinset={index}
                    aria-setsize={matchingOptionsTotal}
                    checkIcon={null}
                    style={
                      selectedKey === option.clientId
                        ? {
                            backgroundColor: tokens.colorBrandBackground2,
                          }
                        : undefined
                    }
                  >
                    <Persona
                      name={option.clientName}
                      secondaryText={option.address}
                      presence={{
                        status: option.isOrganization ? "available" : "away",
                        icon: option.isOrganization ? <CorporateClientIcon /> : <PrivateClientIcon />,
                      }}
                      presenceOnly
                      size="huge"
                    />
                  </Option>
                </Tooltip>
              );
            }}
          </Virtualizer>
        </VirtualizerContextProvider>
      </Combobox>
    </div>
  );
};
